import React from 'react';
import './App.css';
import backgroundImage from './1141EB44-.jpg'
import Helmet from 'react-helmet'

function App() {
    return (
        <React.Fragment>
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Pacifico&display=swap" rel="stylesheet"/>
            </Helmet>
            <div className="App" style={{backgroundImage: `url(${backgroundImage})`}}>
                <h1 style={{paddingTop: '25%', fontSize: 50, color: 'white', fontFamily: 'Pacifico'}}>Coming Soon</h1>
            </div>
        </React.Fragment>
    );
}

export default App;
